import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
var NumberFormat = require('react-number-format');

class App extends Component {

  constructor(props) {
      super(props);

      this.state = {
          cryptos: []
      };
  }

  componentDidMount() {
      axios.get('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,LTC,DASH,ZRX,XRP,MKR,EOS,USDT,TRX&tsyms=USD&api_key=d1256bb057a5b3bb0917dcbc8eccf155ac94e93ee6095c59dc8785d1ca627fcf')
        .then(res => {
            const cryptos = res.data;
            console.log(cryptos);
            this.setState({cryptos: cryptos});
        })
  }

  render() {
    return (

      <div className="App">
          <h1 className="crypto">Crypto Trading Project</h1>
        {Object.keys(this.state.cryptos).map((key) =>  (

            <div id="crypto-container">
                <span className="left">{key}</span>
                <span className="right"><NumberFormat value={this.state.cryptos[key].USD} displayType={'text'} decimalPrecision={2} thousandSeparator={true} prefix={'$'} /></span>
            </div>

        ))}
      </div>
    );
  }
}

export default App;
